import { graphql } from 'gatsby';

import PageBuilder from '../templates/GenericTemplate';

export default PageBuilder;

export const query = graphql`
  query GenericPageQuery {
    prismicGenericTemplate(uid: { eq: "homepage2" }) {
      ...GenericTemplateSchema
    }
    prismicSchema {
      ...SchemaInformation
    }
  }
`;

